/* styles for all patient forms */
.back-arrow {
  margin: 10rem 1rem 0 3rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.form-title {
  text-align: center;
}
.arrow-container {
  display: flex;
  align-items: center;
}

.back-arrow .icon {
  margin-right: 0.5rem;
}

.form-content {
  margin: auto;
  margin-bottom: 200px;
  max-width: 900px;
  padding: 0 2%;
  width: 96%;
}

.form-content h2,
.form-content h3 {
  text-align: center;
}

.form-content li {
  margin-bottom: 15px;
  line-height: 1.4rem;
}

/* disclosure of benefits */
.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
  margin-bottom: 3rem;
}
.bottom-border-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
  background-color: transparent;
  width: 100%;
}
/* grievance form */
.lh-center {
  padding-top: 10px;
}
/* grid for grievance */
.grievance-container {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  gap: 10px;
}
/* privacy policy */
.privacy-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  gap: 10px;
}

.privacy-item:nth-child(3) {
  grid-column: span 2;
}

.privacy-item {
  padding: 10px;
}
/* anethesia consent */
.anethesia-ul {
  list-style: none;
  padding: 0;
}

.anethesia-li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.anethesia-li input[type="text"] {
  margin-right: 10px;
  width: 50px;
  text-align: center;
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
  background-color: transparent;
  padding: 5px;
}

/* consent anethesia */
.ca-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
}
/* patient satisfaction */
.satisfaction-container {
  margin: 20px;
  line-height: 1.5;
}

.pa-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.question-label {
  flex: 1;
}

.options {
  display: flex;
  gap: 20px;
}

.options input[type="radio"] {
  margin-left: 5px;
}

.comments-section {
  margin-top: 20px;
}

.comments-section textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}
/* anethesia questionaire */
.aq-inputs-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 2px solid #000;
}

.aq-input-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #000;
  flex: 1;
  padding: 5px;
}

.aq-input-item label {
  font-size: 14px;
  font-weight: bold;
}

.aq-input-item input {
  width: 80%;
  padding: 5px;
  border: none;
}
.aq-medical-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.aq-form-section {
  width: 50%;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px;
  resize: none;
}

.short-input {
  width: 50px;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}

.long-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}
/* have you had */

.aq-form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.aq-form-list li {
  margin: 0;
}
.aq-form-options {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.aq-option-label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.list-letters {
  padding-left: 3rem;
}
.checkbox {
  margin: 0;
}
.hyh-title {
  width: 60em;
}
.aq-pregnant {
  padding: 0;
}
/* discharge instructions */
.di-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, auto);
  gap: 10px;
}
.si-container {
  display: flex;
  align-items: center;
}
.si-text {
  white-space: nowrap;
}
.di-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
}
.di-followup input,
.di-followup label {
  margin-right: 8px;
}
.di-followup {
  display: flex;
}
/* patient history */
.pi-container {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  margin: 20px 0;
  white-space: nowrap;
}

.pi-form-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 12px;
}

.form-row:last-child {
  border-bottom: none;
}

.pi-form-row label {
  font-weight: bold;
}

.pi-form-row input {
  border: none;

  padding: 2px;
  flex-grow: 1;
}

.pi-short-input {
  width: 80px;
}

.pi-long-input {
  flex: 1;
}

.ph-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
}

.ph-grid-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ph-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.ph-long-input {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  margin-bottom: 10px;
}

.ph-checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ph-grid-item div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ph-grid-item div label {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-basis: 48%;
}
.ph-checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ph-checkbox {
  margin: 0;
}
.sa-form {
  margin: 20px 0;
}

.sa-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.sa-row span {
  margin-right: 10px;
}

.sa-checkbox {
  margin: 0 5px;
}

.sa-input-short {
  width: 2rem;
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
}

.sa-input-long {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
}
.sa-text {
  padding-left: 5px;
}
.ph-container {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  gap: 10px;
}

.hp-form p {
  margin: 10px 0;
}

.hp-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hp-row p {
  min-width: 150px;
  margin: 0;
}

.hp-bottom-border-input,
.employment-form input {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}

.hp-checkbox-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.hp-checkbox {
  margin-right: 10px;
}
/* patient registration */

.pr-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pr-form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.pr-form-row label {
  margin: 0;
  white-space: nowrap;
}

.radio-group {
  display: flex;
  gap: 15px;
  align-items: center;
}

.pr-form-row input[type="radio"] {
  vertical-align: middle;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pr-input-line {
  flex: 1;
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
}

.pr-input-long-line {
  flex: 2;
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
}

input[type="radio"] {
  margin-right: 5px;
}

.pr-form-row:last-child {
  margin-bottom: 0;
}

.pr-container {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
}

.pr-row {
  display: flex;
  width: 100%;
}

.pr-col-20 {
  width: 20%;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.pr-col-80 {
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
}

.employment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.employment-row label {
  margin-right: 10px;
}

.employment-input {
  border: none;
  border-bottom: 1px solid #000;
  margin-right: 10px;
  flex: 1;
}

.employment-input-long {
  border: none;
  border-bottom: 1px solid #000;
  margin-right: 10px;
  flex-grow: 1;
}
.ef-text {
  margin-top: 0;
}
input[type="radio"] {
  margin-right: 5px;
}
.ie-row {
  align-items: center;
}
.ie-short-input {
  width: 15px !important;
}
.pr-row label {
  white-space: nowrap;
}

/* nurse forms */

.nurse-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(17, auto);
}

.nurse-grid-item {
  border: 1px solid #ccc;
  text-align: center;
  font-size: 16px;
}
.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.ssc-text {
  border-bottom: 2px solid black;
  padding: 10px;
  margin: 0;
  font-weight: bold;
}
.ssc-input {
  display: flex;
  margin-bottom: 15px;
}
.ssc-input p {
  margin: 0;
  padding-left: 10px;
}
.form-title-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
}
.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 4px;
}

.checkbox-container label {
  text-align: left;
}
.procedure-container {
  margin: 20px;
}

.procedure-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.procedure-line {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin-left: 10px;
}

.call-log {
  display: flex;
  flex-direction: column;
}

.call-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.call-row label {
  margin-right: 10px;
}

.call-input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  margin-right: 10px;
  width: 150px;
  padding: 2px;
}

.call-input.short {
  width: 80px;
}
.form-container {
  margin: 20px;
}

.pop-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.symptom {
  flex: 2;
  text-align: left;
  color: #000;
  font-size: 16px;
}

.yes-no {
  flex: 0.5;
  text-align: center;
}

.comments {
  flex: 3;
  border: none;
  border-bottom: 1px solid black;
  margin-left: 10px;
}
.no-border {
  border: none;
  font-size: 16px;
  color: #000000;
}
.bottom-sig {
  margin-top: 4rem;
  display: flex;
  align-items: flex-end;
}
.bottom-sig input {
  border: none;
  border-bottom: 1px solid black;
}
.bottom-sig label {
  padding-right: 15px;
}
.sig-date {
  padding-left: 15px;
}
.papo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.papo-row {
  display: flex;
  width: 100%;
}

.papo-column {
  padding: 5px;
}

.papo-column:first-child {
  flex: 95;
}

.papo-column:nth-child(2),
.papo-column:nth-child(3) {
  flex: 2.5;
}
.papo-row .papo-column:nth-child(2) input {
  width: 5rem;
  border: none;
  border-bottom: 1px solid black;
}

.papo-row .papo-column:nth-child(3) input {
  width: 5rem;
  border: none;
  border-bottom: 1px solid black;
}
.papo-form-input {
  width: 75px;
  border: none;
  border-bottom: 1px solid black;
}
.papo-form-input-long {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
}
.sig-input {
  border: none;
  border-bottom: 1px solid black;
}
.papo-column input[type="checkbox"] {
  margin-left: 35px;
}
.papo-title-text {
  white-space: nowrap;
}
.papo-sig-container {
  margin: 5rem 0;
}

.loc-label {
  font-weight: bold;
  margin-right: 10px;
}

.loc-option {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.loc-option input {
  display: none;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid black;
  line-height: 20px;
  text-align: center;
  margin-right: 5px;
  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s;
}

.loc-option.selected .circle {
  background-color: black;
  color: white;
}

.iof-form-section {
  margin-bottom: 20px;
}

.iof-form-label {
  font-weight: bold;
  margin-right: 10px;
}

.iof-form-checkbox {
  display: inline-block;
  margin-right: 20px;
}

.inline-inputs {
  display: inline-block;
  margin-left: 20px;
}

.form-input-short {
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;

  padding: 2px;
}
.procedure-input {
  width: 50%;
}
input[type="checkbox"] {
  margin-right: 5px;
}
.vital-sign-record {
  font-family: Arial, sans-serif;
  font-size: 14px;
  border: 1px solid black;
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  border-bottom: 1px solid black;
}

.header {
  font-weight: bold;
}

.cell {
  flex: 1;
  padding: 5px;
  border-right: 1px solid black;
  text-align: center;
}

.cell:last-child {
  border-right: none;
}
.ir-no-border {
  border: none;
  padding: 0;
  margin: 0;
  width: 100px;
}

.mr-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
}

.mr-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
}

.mr-cell {
  flex: 1;
  height: 50px;
  border-right: 1px solid black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-row .mr-cell:last-child {
  border-right: none;
}
.mc-section {
  display: flex;
  margin-bottom: 20px;
}
.cc-container {
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: center;
}
.page-column {
  flex: 0 0 10%;
  text-align: center;
}

.initials-column {
  flex: 0 0 10% !important;
}

.rs-column {
  flex: 0 0 80%;
}
.preoppc-container {
  display: flex;
  white-space: nowrap;
  margin-bottom: 10px;
  align-items: center;
}
.preoppc-container input {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
}
.preoppc-form-group label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.preoppc-form-group-2 {
  display: flex;
  align-items: center;
}
.sc-no-margin {
  margin: 0 !important;
}
.sc-no-margin label {
  margin-right: 10px;
}
.form-divider {
  width: 100%;
  border-bottom: 2px solid black;
  margin: 20px 0;
}
.label-heading {
  margin: 8px 15px;
}
.label-indent {
  margin: 16px 30px;
  line-height: 1.7;
}
.title-text-h3 {
  font-size: 20px;
  font-weight: bold;
}
.counts-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.counts-input {
  border: none;
  width: 50px;
}
.counts-item {
  padding: 3px;
  border: 1px solid #ccc;
}
.arrow-container {
  display: flex;
  justify-content: space-between;
}
.flex-text {
  display: flex;
  justify-content: space-between;
}
.pir-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pi-item {
  border: 1px solid #ccc;
  padding: 3px;
}
.outcome-container {
  margin: 20px;
  display: grid;
  display: grid;
  grid-template-columns: 80% 10% 10%;
}
.outcome-item {
  padding: 3px;
}
.local {
  margin: 20px 0;
}
.preop-meds-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Row styling */
.preop-meds-row {
  display: flex;
  justify-content: space-between;
}

/* Column styling */
.preop-meds-column {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.preop-meds-column:first-child {
  margin-left: 0;
}

.preop-meds-column:last-child {
  margin-right: 0;
}
.pacu-container {
  display: flex;
  flex-direction: column;
}
.pacu-row {
  display: flex;
}
.pacu-cell {
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 80px;
  text-align: center;
}
.pacu-input {
  border: none;
  background: none;
  min-width: 80px;
}
.table-center {
  display: flex;
  justify-content: center;
}
.post-op-input {
  margin-right: 10px;
  width: 50px;
  text-align: center;
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
  background-color: transparent;
  padding: 5px;
}
.billing-supply-grid-container {
  display: grid;
  grid-template-columns: 40% 10% 40% 10%;
  grid-template-rows: repeat(37, auto);
}

.billing-supply-grid-item {
  padding: 10px;
}
.billing-supply-grid-item input {
  text-align: left !important;
}
.ar-grid-container {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(19, auto);
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.ar-input {
  border: none;
  width: 20px;
}
.ar-grid-item {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}
.ar-flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ar-flex-2 {
  display: flex;
  align-items: center;
}
.ar-label {
  padding-right: 10px;
}
.psq-inputs input {
  margin-left: 15px;
}
.billing-supply-ta {
  display: flex;
  gap: 30px;
}
@media only screen and (min-width: 600px) {
  .grid-2 {
    align-items: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (min-width: 350px) and (max-width: 425px) {
  .patient-tabs {
    padding: 0;
  }
  .mr-row,
  .preop-meds-row {
    font-size: 10px;
  }
  .ir-no-border {
    width: 0;
  }
}

@media only screen and (max-width: 768px) {
  .preop-meds-container {
    padding: 0;
  }
  .ir-no-border {
    width: 0;
  }
  .bj-mobile-margin {
    margin: 0;
  }
  .pacu-input {
    width: auto;
  }
  .pacu-cell {
    min-width: 20px;
  }
  .call-row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .symptom {
    flex: 1 1;

    font-size: 8px;
  }
  .no-border,
  .pop-form-row p,
  .pop-form-row label {
    font-size: 8px;
  }
  .call-row label {
    margin-bottom: 10px;
  }
  .di-followup input,
  .di-followup label,
  .iof-form-section label {
    margin-bottom: 10px;
  }
  .mobile,
  .iof-form-section {
    display: flex !important;
    flex-direction: column;
  }
  .satisfaction-container {
    margin: 10px;
  }
  .cell-flex {
    flex-direction: column;
  }
  .bj-flex {
    display: flex;
  }

  .preoppc-container,.arrow-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .preoppc-form-group {
    margin-bottom: 10px;
  }
  .preoppc-form-group p {
    white-space: pre-wrap;
  }
  .mobile-two {
    align-items: flex-start !important;
  }
  .comments-section textarea {
    width: 94%;
  }
  .aq-form-section {
    width: 100%;
  }
  .aq-medical-form {
    flex-direction: column;
  }
  .aq-form-list {
    padding-left: 5px;
  }
  .aq-form-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .aq-form-item p {
    white-space: wrap;
  }
  .hyh-title {
    width: auto;
  }
  .pi-form-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .aq-form-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .long-input {
    width: 98%;
  }
  .ph-grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 100%;
  }

  .ph-grid-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .ph-label {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .ph-long-input {
    margin-bottom: 8px;
    width: 100%;
  }

  .ph-checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .ph-checkbox-label strong {
    margin-left: 5px;
  }
  .sig-mobile {
    display: flex;
    flex-direction: column;
  }
  .pr-form-row,
  .radio-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .pr-row {
    flex-direction: column;
  }
  .pr-col-20 {
    width: 100%;
    display: block;
    text-align: center;
  }
  .ie-row {
    align-items: flex-start;
  }
  .pr-col-80,
  .employment-input,
  .employment-input-long,
  .ie-short-input {
    width: 100% !important;
  }
  .employment-input {
    margin-bottom: 10px;
  }
  .employment-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pr-row label {
    white-space: pre-wrap;
  }
  .nurse-grid-container {
    flex-direction: column;

    display: flex !important;
  }
  .ssc-input {
    flex-direction: column;
  }
  .rs-column {
    flex: auto;
  }
}
.mt-50 {
  margin-top: 50px;
}
.name-input {
  width: 100%;
  padding: 20px 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
  font-size: 20px;
}
